<template>
	<span>
		<validation-provider
			mode="lazy"
			:name="confirmation(slug)"
			:rules="rules"
			:class="[ css ]"
			v-slot="{ errors }"
			tag="div"
			class="line"
		>
			<label :for="confirmation(slug)" v-html="`${caps(confirmationLabel(label))} ${required('*')}`" class="label"></label>
			<input :id="confirmation(slug)" :data-id="confirmationElementId(formElementId)" v-model="value" type="password" class="input">
			<span v-if="errors.length" class="error password-input">{{ errors[0] }}</span>
		</validation-provider>
	</span>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { mixinFormElements } from '@/components/mixins/form-elements'

	export default {
		name: 'ContentFormAcceptinput',
		mixins: [ mixinFormElements ],
		components: {
			ValidationProvider,
			ValidationObserver
		},
	}
</script>

<style scoped lang="stylus">
	@import '../../../assets/css/account-creation'
</style>
