import { allBeginsWith, applyValuesOnString } from '@/js/utils/utils'
import { mapGetters } from 'vuex'
import Apollo from "@/apollo/apollo-client"
import { capitalizeOnLanguage } from '@/js/utils/utils'
import { LANGUAGE } from '@/js/factory/language'

export const mixinFormElements = {
	props: [ 'data', 'isWithConfirmation', 'context' ],
	data() {
		const CSS = 'CSS-'
		const RULE = 'RULE-'
		return {
			CSS,
			RULE,
			value: null
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		selectItems() {
			const { $props: { context = {}, data: { selectableElements = [] } = {} } = {} } = this
			return [
				...selectableElements
					.map(x => {
						const { id:value, option: { html:option } } = x
						return {
							option,
							value
						}
					})
			]
		},
		url() {
			const { $props: { context = {}, data: { url = '' } = {} } = {} } = this
			return url
		},
		html() {
			const { $props: { context = {}, data: { html:text = '' } = {} } = {} } = this
			return applyValuesOnString.call(context, { text })
		},
		formElementId() {
			const { $props: { data: { formElementId } = {} } = {} } = this
			return formElementId
		},
		slug() {
			const { $props: { data: { slug } = {} } = {} } = this
			return slug
		},
		label() {
			const { $props: { data: { label } = {} } = {} } = this
			return label
		},
		rules() {
			const { RULE, $props: { isWithConfirmation = false, data: { isRequired, elementType, slug, tags } = {} } = {} } = this
			let rules = ''
			rules += isRequired
				? 'required'
				: ''
			switch(elementType) {
				case 'Content::Form::PasswordInput':
					if (!isWithConfirmation) rules += '|strongPassword'
					break;
				case 'Content::Form::AcceptInput':
					rules += isRequired
						? '|is_true'
						: ''
					break
			}
			if (isWithConfirmation) rules += `|confirmed:${slug}`
			const rule = (allBeginsWith(tags, RULE)[0] || '').replace(RULE, '')
			if (rule) rules += `|${rule}`
			return rules
		},
		css() {
			const { CSS, $props:{ data:{ tags = []} = {} } = {}} = this
			return (allBeginsWith(tags, CSS)[0] || '').replace(CSS, '')
		},
		isMultipleChoices() {
			const { $props: { context = {}, data: { maxInput = 1 } = {} } = {} } = this
			return maxInput > 1 || false
		},
		maxInput() {
			const { $props: { context = {}, data: { maxInput = 1 } = {} } = {} } = this
			return maxInput
		}
	},
	methods: {
		caps(value) {
			return value
			// return capitalizeOnLanguage(['en'], LANGUAGE.getData(), value)
		},
		async termsAndConditions() {
			this.termsLoading = true
			const query = 'CONTENT_TEXT_BY'
			const variables = { slug: 'terms_and_conditions' }
			this.termsLoading = false
			const src = (((await Apollo.query('CONTENT_TEXT_BY', variables)) || [])[0] || {}).html || ''
			return { src }
		},
		open_url(url) {
			if (url) window.open(url, '_blank').focus()
		},
		required(str) {
			const { data: { isRequired = false } = {} } = this
			return isRequired
				? str
				: ''
		},
		confirmationElementId(id) {
			const { isWithConfirmation } = this
			return isWithConfirmation
				? null
				: id
		},
		confirmationLabel(label) {
			const { isWithConfirmation } = this
			return isWithConfirmation
				? `${label} (confirmation)`
				: label
		},
		confirmation(x) {
			const { isWithConfirmation } = this
			return isWithConfirmation
				? `${x}Confirmation`
				: x
		},
	},
	updated() {
		this.value = null
	}
}
