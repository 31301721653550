<template>
	<img
		:src="url"
		:context="context"
		:class="[ css ]"
	/>
</template>

<script>
	import { mixinFormElements } from '@/components/mixins/form-elements'
	import { allBeginsWith } from '@/js/utils/utils'

	export default {
		name: 'ClientMedia',
		mixins: [ mixinFormElements ],
		data() {
			return {
				test: {
					'background-color': 'black'
				}
			}
		},
		computed: {
			css() {
				const { $props:{ data:{ tags = []} = {} } = {}} = this
				return  (allBeginsWith(tags, 'CSS-')[0] || '').replace('CSS-', '')
			}
		},
	}
</script>
