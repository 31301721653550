<template>
	<span>
		<validation-provider
			:name="slug"
			:rules="rules"
			:class="[ css ]"
			v-slot="{ errors }"
			tag="div"
			class="line"
		>
			<div class="label">{{ `${caps(label)} ${required('*')}` }}</div>
			<multiselect
				v-if="isMultipleChoices"
				class="stack-multi-select"
				@open="readOnly"
				ref="multi"
				:id="slug"
				:data-id="formElementId"
				:name="slug"
				v-model="value"
				:options="selectItems"
				:multiple="true"
				label="option"
				track-by="option"
				:placeholder="placeholder"
				selectLabel=""
				:deselectLabel="deselectLabel"
				:allowEmpty="true"
				:closeOnSelect="false"
				:limitText="limitText"
				:limit="1"
			>
			</multiselect>
			<multiselect
				v-else
				class="stack-multi-select"
				@open="readOnly"
				ref="multi"
				:id="slug"
				:multiple="false"
				:data-id="formElementId"
				:name="slug"
				v-model="value"
				:options="selectItems"
				label="option"
				track-by="option"
				:placeholder="placeholder"
				selectLabel=""
				deselectLabel=""
				:allowEmpty="false"
			/>
			<span v-if="errors.length" class="error select-input">{{ errors[0] }}</span>
		</validation-provider>
	</span>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { mixinFormElements } from '@/components/mixins/form-elements'
	import Multiselect from 'vue-multiselect'
	import Vue from 'vue'

	export default {
		name: 'ContentFormSelectinput',
		mixins: [ mixinFormElements ],
		components: {
			ValidationProvider,
			ValidationObserver,
			Multiselect
		},
		data() {
			return {
				open: false,
				value: [],
			}
		},
		computed: {
			deselectLabel() {
				const { isMultipleChoices } = this
				return isMultipleChoices
					? Vue.i18n.translate('selectToRemove' )
					: ''
			},
			placeholder() {
				const { isMultipleChoices, selectItems, value } = this
				return isMultipleChoices
					? Vue.i18n.translate('multipleChoices' )
					: value.length
						? value[0].option
						: Vue.i18n.translate('oneChoice' )
			},
		},
		methods: {
			limitText(count) {
				return Vue.i18n.translate('andMore')
			},
			readOnly() {
				this.$refs.multi.$refs.search.setAttribute('readOnly', true)
			},
		},
		updated() {
			// this.open = false
		}
	}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="stylus">
	@import '../../../assets/css/account-creation'
	@import '../../../assets/css/custom-select'

	.stack-multi-select
		font-size 1em
		margin 4px 0 0 0
		>>> *
			font-size 1em
		>>> .multiselect__option--selected
			font-size 1.1em
			font-weight bold
			color #41b883
		>>> .multiselect__option--highlight
			color inherit

	.values
		display flex
		flex-direction column
		background-color white
		border-radius 20px
		color black
		width 250px
		max-height 300px
		cursor pointer
	.closed
		height 30px
	.open
		height 200px
	.selected
		background-color black
		color white
</style>
