import * as VeeValidateRules from 'vee-validate/dist/rules'
import { isEmpty } from 'lodash'

export const getValidation = function({ object, fieldName }){
	if (!object || !fieldName) return
	const field = object[fieldName]
	if (!field) return
	const {needed, validation} = field
	let { type } = field
	type = type.toLowerCase()
	const { type: validationType = null, params = {} } = validation
	const listeValidations = Object.keys(VeeValidateRules)
	let result = needed
		? 'required'
		: ''
	result = !['editor', 'string'].includes(type.toLowerCase())
		? `${result ? `${result}|` : ''}${type.toLowerCase()}`
		: result
	if (
		validationType
		&& listeValidations.includes(validationType)
	) {
		let validation = validationType
		if (!isEmpty(params)) validation += `:${Object.entries(params || {}).reduce((a, c) => a ? `${a},${c[1]}` : `${c[1]}`, '')}`
		result = `${result}|${validation}`
	}
	return result
}
